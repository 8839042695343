import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';

import {
    Slider,
    SliderTools,
    SharedStyles,
    Container,
} from '../kenra-storybook/index';

const { StSliderTools } = SliderTools;
const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

const StylistTools = ({ intl, tools, count }) => {
    return (
        <StSectionMobileViewMore>
            <StSectionTitle>
                <Container>
                    <h2>
                        {intl.formatMessage({ id: 'application-cards-title' })}
                    </h2>
                    <StLink mobileStyleLinkMore>
                        <Link to={urls.applications}>{`${intl.formatMessage({
                            id: 'view-all-text',
                        })} (${count ? count : tools.length})`}</Link>
                    </StLink>
                </Container>
            </StSectionTitle>
            <Slider settings={{ slidesPerView: 'auto' }}>
                {tools.map((application, index) => (
                    <StSliderTools key={index}>
                        <Link to={`/application/${application.title}`}>
                            <img
                                src={get(
                                    application,
                                    'image.localFile.square.resize.src'
                                )}
                                alt=""
                            />
                            <h3>{application.title}</h3>
                        </Link>
                    </StSliderTools>
                ))}
            </Slider>
        </StSectionMobileViewMore>
    );
};

export default injectIntl(StylistTools);
