import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Education from 'components/EducationV3';
import SEO from '../components/seo';
import get from 'lodash.get';

export default function EducationPage(props) {
    let {
        data: {
            allContentfulEvent: { nodes: events },
            contentfulEducationPage,
            allContentfulTool: { nodes: tools },
        },
    } = props;

    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    let filteredEvents = events.filter(event => {
        if (
            typeof event.region === 'undefined' ||
            event.region === null ||
            event.region.length === 0 ||
            event.region.includes(storedRegion)
        ) {
            return true;
        } else {
            return false;
        }
    });

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulEducationPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulEducationPage, 'seoListing.seoTitle')}
                slug={'education'}
            />
            <Education
                page={contentfulEducationPage}
                events={filteredEvents}
                allTools={tools}
            />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulEducationPage(node_locale: { eq: $locale }) {
            title
            videoBanner {
                localFile {
                    publicURL
                }
            }
            videoBannerPreview {
                localFile {
                    publicURL
                }
            }
            text {
                raw
            }
            headerTitle {
                raw
            }
            headerContent {
                raw
            }
            headerImages {
                title
                description
                localFile {
                    publicURL
                }
            }
            headerImageUrls
            tools {
                ...ContentfulToolFields
            }
            videoImage {
                localFile {
                    publicURL
                }
            }
            videos {
                ...ContentfulVideoFields
            }
            secondaryVideos {
                ...ContentfulVideoFields
            }
            playlists {
                ...ContentfulVideoFields
            }
            videoBlockTitle
            videoBlockTitleInfo
            videoBlockSubtitle
            topTextItems {
                items {
                    text
                    title
                }
                title
            }
            background {
                localFile {
                    publicURL
                }
            }
            educatorBackground {
                localFile {
                    publicURL
                }
            }
            inSalonImage {
                localFile {
                    publicURL
                }
            }
            inSalonRequestImage {
                localFile {
                    publicURL
                }
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
        allContentfulEvent(filter: { node_locale: { eq: $locale } }) {
            nodes {
                name
                image {
                    localFile {
                        publicURL
                    }
                }
                date
                url
                endTime
                shortDescription {
                    shortDescription
                }
                aboutThisEvent {
                    aboutThisEvent
                }
                aboutThisEventRtf {
                    raw
                }
                aboutTheInstructor {
                    aboutTheInstructor
                }
                preparation {
                    preparation
                }
                bookingButtonText
                bookingButtonLink
                whereCoOrdinates {
                    lat
                    lon
                }
                where {
                    where
                }
                node_locale
                region
            }
        }
        allContentfulTool(filter: { node_locale: { eq: $locale } }) {
            nodes {
                ...ContentfulToolFields
            }
        }
    }
`;
