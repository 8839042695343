import React from 'react'
import get from 'lodash.get'
import styled, { css } from 'styled-components'
import urls from 'helpers/url'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import CustomLink from 'components/Link'
import settings from '../kenra-storybook/global/settings'
import {
    Slider,
    SliderTools,
    SharedStyles,
    Container,
} from '../kenra-storybook/index'


const StContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const StColumn = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 50%;
`

const StItem = styled.div`
    display: flex;
    margin: 4px;
`


const StSmallItem = styled.div`
    display: flex;
    width: calc(50% - 8px);
    margin: 4px;
`

const { StSliderTools } = SliderTools
const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles

const StylistTools = ({ intl, tools, count, hideTitle }) => {
    return (
        <StSectionMobileViewMore>
            {!hideTitle && <StSectionTitle noBottomMargin black alignLeft removePadding>
                <Container unsetMaxWidth>
                    <h2 style={{ color: '#000', fontSize: '28px' }}>
                        {intl.formatMessage({ id: 'stylist-tools-title' })}
                    </h2>
                    <StLink mobileStyleLinkMore>
                        <Link to={urls.tools}>{`${intl.formatMessage({
                            id: 'view-all-text',
                        })} (${count ? count : tools.length})`}</Link>
                    </StLink>
                </Container>
            </StSectionTitle>}

            <StContentWrapper>
                <StColumn>
                    <StItem>
                        <CustomLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href={get(tools[0], 'file.localFile.publicURL')}
                        >
                            <img
                                src={get(
                                    tools[0],
                                    'image.localFile.square.resize.src'
                                )}
                                alt=""
                            />
                        </CustomLink>
                    </StItem>
                </StColumn>
                <StColumn>
                    {tools.map((manual, index) => {
                        if (index !== 0 && index <= 4) {
                            return (
                                <StSmallItem>
                                    <CustomLink
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={get(manual, 'file.localFile.publicURL')}
                                    >
                                        <img
                                            src={get(
                                                manual,
                                                'image.localFile.square.resize.src'
                                            )}
                                            alt=""
                                        />
                                    </CustomLink>
                                </StSmallItem>
                            )
                        }
                    })}
                </StColumn>
            </StContentWrapper>

            {/* <Slider settings={{ slidesPerView: 'auto' }}>
                {tools.map((manual, index) => (
                    <StSliderTools key={index}>
                        <img
                            src={get(
                                manual,
                                'image.localFile.square.resize.src'
                            )}
                            alt=""
                        />
                        <h3>{manual.title}</h3>
                        <StLink>
                            <CustomLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={get(manual, 'file.localFile.publicURL')}
                            >
                                {intl.formatMessage({ id: 'download-text' })}
                            </CustomLink>
                        </StLink>
                    </StSliderTools>
                ))}
            </Slider> */}
        </StSectionMobileViewMore>
    )
}

export default injectIntl(StylistTools)
