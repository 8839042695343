import React, { useState } from 'react'
import { parseISO, format } from 'date-fns'
import get from 'lodash.get'
import urls from 'helpers/url'
import { getEventUrl } from 'helpers/eventUrl'
import styled from 'styled-components'
import { sortByDate } from 'helpers/sort'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import Button from '../../kenra-storybook/components/atoms/AllButtonAlt'
import { SharedStyles, Container, Events } from '../../kenra-storybook/index'

const { StSectionTitle, StLink } = SharedStyles

const StyledButton = styled(Button)`
    margin-top: 6px;
`

const EducationEvents = ({ intl, events }) => {
    let [sortAsc, setSortAsc] = useState(true)

    if (!events || events.length <= 0) {
        return null
    }

    let now = new Date()
    let filteredEvents = events.filter(event => {
        let date = new Date(event.date)
        // Event already passed
        if (date - now < 0) return false
        return true
    })

    if (!filteredEvents || filteredEvents.length <= 0) {
        return null
    }

    let sortedEvents = sortByDate(filteredEvents, true)
    let [closestEvent, ...otherEvents] = sortedEvents

    otherEvents = sortByDate(otherEvents, sortAsc).slice(0, 3)

    return (
        <Container almostFullWidth fullWidth>
            <section>
                <StSectionTitle smallBottomMargin>
                    <Container style={{ padding: 0, paddingTop: 20, width: '100%', margin: 0, display: 'flex', flexDirection: 'row', maxWidth: '2000px' }}>
                        <h2 style={{ color: 'black', fontSize: '28px', flexGrow: 1, textAlign: 'left' }}>
                            {intl.formatMessage({
                                id: 'education-upcoming-events',
                            })}
                        </h2>
                        <StLink style={{ letterSpacing: '2px' }}>
                            <Link to={urls.eventList}> {intl.formatMessage({ id: 'calendar-view' })}</Link>
                        </StLink>
                    </Container>
                </StSectionTitle>
                <Events
                    newStyle
                    mainItem={{
                        img: get(closestEvent, 'image.localFile.publicURL'),
                        // type: 'UPCOMING',
                        typeColor: '#aa58b8',
                        title: closestEvent.name.toUpperCase(),
                        // text: `<p>${get(
                        //     closestEvent,
                        //     'shortDescription.shortDescription'
                        // )}</p>`,
                        // moreLink: getEventUrl(closestEvent),
                        // moreText: intl.formatMessage({
                        //     id: 'more-events-text',
                        // }),
                    }}
                    onSortChange={() => setSortAsc(!sortAsc)}
                    startOn={format(parseISO(closestEvent.date), 'MMMM d, Y')}
                    items={otherEvents.map(event => ({
                        img: get(event, 'image.localFile.publicURL'),
                        title: event.name,
                        dayWeek: format(parseISO(event.date), 'E'),
                        date: format(parseISO(event.date), 'MMMM d, Y').toUpperCase(),
                        time: format(parseISO(event.date), 'h:mma'),
                        link: getEventUrl(event),
                    }))}
                />
            </section>
        </Container>
    )
}

export default injectIntl(EducationEvents)
