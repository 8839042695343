import React from 'react'
import get from 'lodash.get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import VideoList from 'components/VideoList'
import StylistTools from 'blocks/StylistToolsBlocks'
import ApplicationCards from 'blocks/ApplicationCards'
import EducationEvents from './EducationEvents'
import url from 'helpers/url'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import {
    Spacing,
    SharedStyles,
    Container,
    ImageGrid,
    InSalonRequestBanner,
    VideoBlock,
} from '../../kenra-storybook/index'
const { StSectionTitle, StLink } = SharedStyles


const APPLICATION_CARD_TYPE = 'Application card'
const COLOR_MANUAL_TYPE = 'Color manual'

const EducationPage = ({ intl, page, events, allTools }) => {
    let cards = []
    let manuals = []
    const { tools } = page
    if (tools) {
        cards = tools.filter(tool => tool.type === APPLICATION_CARD_TYPE)
        manuals = tools.filter(tool => tool.type === COLOR_MANUAL_TYPE)
    }
    let allCards = []
    let allManuals = []
    if (allTools) {
        allCards = allTools.filter(tool => tool.type === APPLICATION_CARD_TYPE)
        allManuals = allTools.filter(tool => tool.type === COLOR_MANUAL_TYPE)
    }
    const toolsCount = allManuals.length
    const applicationsCount = allCards.length

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <VideoBlock
                    renderVideo
                    removePlayCircle
                    imgUrl={get(page, 'videoBannerPreview.localFile.publicURL')}
                >
                    <video preload="metadata">
                        <source
                            src={get(page, 'videoBanner.localFile.publicURL')}
                            type="video/mp4"
                        />
                        {intl.formatMessage({
                            id: 'kenra-video-not-supported',
                        })}
                    </video>
                </VideoBlock>
            </Spacing>
            <Spacing addHorizontalMargin>
                <StSectionTitle noBottomMargin removePadding grey alignLeft large>
                    <Container>
                        {documentToReactComponents(
                            JSON.parse(page.headerTitle.raw || '{}')
                        )}
                    </Container>
                </StSectionTitle>
            </Spacing>
            <Spacing addHorizontalMargin removeSpaceTop>
                <StSectionTitle noBottomMargin blackHeader removePadding alignLeft>
                    <Container>
                        {documentToReactComponents(
                            JSON.parse(page.headerContent.raw || '{}')
                        )}
                    </Container>
                </StSectionTitle>
                {page.headerImages && (
                    <ImageGrid
                        outlined
                        smallImages
                        learnMore
                        items={page.headerImages.map((image, index) => {
                            let href
                            if (
                                page.headerImageUrls &&
                                page.headerImageUrls[index]
                            ) {
                                href = page.headerImageUrls[index]
                            }
                            return {
                                img: get(image, 'localFile.publicURL'),
                                title: get(image, 'title'),
                                description: get(image, 'description'),
                                href,
                            }
                        })}
                    />
                )}
            </Spacing>

            <Spacing addHorizontalMargin removeSpaceTop removeSpaceBottom style={{ backgroundColor: '#F8F8F7' }}>
                <EducationEvents events={events} />
            </Spacing>

            {page.videos && page.videos.length > 0 && (
                <Spacing addHorizontalMargin removeSpaceBottom>
                    <StSectionTitle noBottomMargin black alignLeft>
                        <h2 style={{ marginBottom: '0px' }}>
                            {page.videoBlockTitle}
                        </h2>
                        <StLink mobileStyleLinkMore>
                            <Link to={url.videos}>{`${intl.formatMessage({
                                id: 'view-all-text',
                            })}`}</Link>
                        </StLink>
                    </StSectionTitle>
                    {page.videoBlockTitleInfo && <StSectionTitle noBottomMargin black alignLeft grey>
                        <h3 style={{ marginBottom: '6px' }}>
                            {page.videoBlockTitleInfo}
                        </h3>
                    </StSectionTitle>}

                    <VideoList
                        videos={page.videos}
                        hideTopics={true}
                        numberInRow={3}
                        removeTitle
                        // removeOverlay
                        removeHeaderBar
                        bitBigger
                    />
                </Spacing>
            )}
            {page.playlists && page.playlists.length >= 1 && (
                <Spacing removeSpaceTop
                    removeSpaceBottom
                    addHorizontalMargin >
                    <StSectionTitle noBottomMargin black alignLeft removePadding>
                        <h2 style={{ marginBottom: '12px' }}>
                            {page.videoBlockSubtitle}
                        </h2>
                        <StLink mobileStyleLinkMore>
                            <Link to={url.videos}>{`${intl.formatMessage({
                                id: 'view-all-text',
                            })}`}</Link>
                        </StLink>
                    </StSectionTitle>
                    <VideoList
                        videos={page.playlists}
                        hideTopics={true}
                        numberInRow={4}
                        alignTitleLeft
                        playlists
                        removeSpaceTop
                        removeOverlay
                        removeTitle
                        bitBigger
                        removeItemTitles
                    />
                </Spacing>
            )}

            <Spacing removeSpaceBottom removeSpaceTop style={{ backgroundColor: '#F8F8F7' }}>
                <InSalonRequestBanner minimal left smallButton items={[
                    {
                        title: 'Looking for In-Salon Education?',
                        btnText: 'request an in-salon class',
                        href: '/in-salon-class-request',
                        url: '/in-salon-class-request',
                        smallImage: page.inSalonImage?.localFile?.publicURL,
                        fullWidth: true,
                        overlayBackground: false,
                    },
                ]} />
            </Spacing>

            {cards && cards.length > 0 && (
                <Spacing>
                    <ApplicationCards tools={cards} count={applicationsCount} />
                </Spacing>
            )}

            {manuals && manuals.length > 0 && (
                <Spacing addHorizontalMargin>
                    <StSectionTitle noBottomMargin black alignLeft removePadding>
                        <h2 style={{ marginBottom: '12px' }}>
                            {intl.formatMessage({ id: 'stylist-tools-title' })}
                        </h2>
                        <StLink mobileStyleLinkMore>
                            <Link to={url.tools}>{`${intl.formatMessage({
                                id: 'view-all-text',
                            })}`}</Link>
                        </StLink>
                    </StSectionTitle>
                    <StylistTools hideTitle tools={manuals} count={toolsCount} />
                </Spacing>
            )}

            <Spacing>
                <InSalonRequestBanner smallButton minimal items={[
                    {
                        title: 'JOIN THE TEAM!',
                        btnText: intl.formatMessage({
                            id: 'education-apply-today',
                        }),
                        href: url.educatorApplication,
                        url: url.educatorApplication,
                        backgroundImage: page.educatorBackground?.localFile?.publicURL,
                        fullWidth: true,
                        overlayBackground: false,
                    },
                ]} />
            </Spacing>

        </>
    )
}

export default injectIntl(EducationPage)
