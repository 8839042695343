import React, { useState } from 'react'

import { getVideoUrl } from 'helpers/url'
import {
    getYouTubeId,
    getEmbedUrl,
    getPreviewUrl,
    getPlaylistEmbedUrl,
} from 'helpers/youtube'
import { multiline, dateFormat } from 'helpers/text'
import { useIntl } from 'gatsby-plugin-react-intl'

import {
    Spacing,
    Slider,
    SliderVideo,
    SharedStyles,
    Container,
    ListOfVideo,
    ButtonPlay,
    PageTopicsControl,
    VideoLightBox,
} from '../kenra-storybook/index'
import styled, { css } from 'styled-components'
import settings from '../kenra-storybook/global/settings'

const {
    StSliderVideoWrap,
    StSliderVideo,
    StSliderVideoModal,
    StSliderVideoElm,
    StSliderVideoDesc,
} = SliderVideo

const { StSectionTitle } = SharedStyles

const ALL_TOPICS = 'All topics'

const StVideoTitle = styled.div`
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 24px;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
    }
    ${props =>
        props.alignLeft &&
        css`
            text-align: left;
            font-size: 22px;
            @media (min-width: ${settings.bp.small.view}) {
                font-size: 22px;
            }
        `};
`

function TopVideos({ topVideos }) {
    const [openedModal, setOpenedModal] = useState(null)

    if (!Array.isArray(topVideos) || topVideos.length === 0) return null

    return (
        <>
            <StSliderVideoWrap>
                <Slider
                    hideProgress
                    settings={{
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        loop: true,
                    }}
                >
                    {topVideos.map((video, index) => {
                        let { id, title, date, youtubeLink } = video
                        let youTubeId = getYouTubeId(youtubeLink)

                        return (
                            <StSliderVideo
                                key={index}
                                onClick={() => setOpenedModal(index)}
                            >
                                <StSliderVideoElm>
                                    <ButtonPlay
                                        useImgOverlay
                                        bgImg={getPreviewUrl(youTubeId)}
                                    />
                                </StSliderVideoElm>
                                <StSliderVideoDesc>
                                    <p>{multiline(title.title)}</p>
                                    {date && <h5>{dateFormat(date)}</h5>}
                                </StSliderVideoDesc>
                            </StSliderVideo>
                        )
                    })}
                </Slider>
            </StSliderVideoWrap>
            <StSliderVideoModal>
                {topVideos.map((video, index) => {
                    let { id, youtubeLink } = video
                    let youTubeId = getYouTubeId(youtubeLink)
                    let youTubeEmbed = getEmbedUrl(youTubeId)
                    return (
                        <VideoLightBox
                            key={index}
                            onHide={() => setOpenedModal(null)}
                            isOpen={openedModal === index}
                            channel="youtube"
                            videoOptions={[
                                {
                                    src: youTubeEmbed,
                                },
                            ]}
                        />
                    )
                })}
            </StSliderVideoModal>
        </>
    )
}

export default function Videos(props) {
    let intl = useIntl()
    let {
        videos,
        hideTopics,
        titleId,
        title,
        numberInRow,
        alignTitleLeft,
        playlists,
        titleStyle,
        removeSpaceTop,
        removeOverlay,
        removeTitle,
        extraMargin,
        removeHeaderBar,
        bitBigger,
        removeItemTitles
    } = props

    let topVideos = videos.filter(v => !!v.showOnVideosList)
    let topics = new Set(videos.map(v => v.topic).filter(Boolean))

    const [selectedTopic, setSelectedTopic] = useState(null)

    let filteredVideos = videos.filter(v => {
        if (
            selectedTopic &&
            selectedTopic !== ALL_TOPICS &&
            selectedTopic !== v.topic
        )
            return false
        return true
    })

    return (
        <>
            {topVideos && <Spacing removeSpaceTop removeSpaceBottom>
                <TopVideos topVideos={topVideos} />
            </Spacing>}

            {!removeHeaderBar && <Spacing removeSpaceBottom removeSpaceTop={removeSpaceTop}>
                <Container>
                    {!hideTopics && (
                        <PageTopicsControl
                            mobileFixedMenu
                            inversionColor
                            styleArrowType2
                            useSelectedTitle
                            title={intl.formatMessage({
                                id: 'video-topics-title',
                            })}
                            onChange={topic => setSelectedTopic(topic)}
                            items={[ALL_TOPICS, ...topics].map(topic => ({
                                title: topic,
                                val: topic,
                            }))}
                        />
                    )}
                    {title || titleId ? (
                        <StVideoTitle
                            style={titleStyle}
                            alignLeft={alignTitleLeft}
                        >
                            {title ||
                                intl.formatMessage({
                                    id: titleId || 'kenra-latest-videos',
                                })}
                        </StVideoTitle>
                    ) : (
                        !removeTitle && (
                            <StSectionTitle alignLeft>
                                <h3>
                                    {intl.formatMessage({
                                        id: titleId || 'kenra-latest-videos',
                                    })}
                                </h3>
                            </StSectionTitle>
                        )
                    )}
                </Container>
            </Spacing>}

            {/* LIST */}

            <ListOfVideo
                bitBigger={bitBigger}
                extraMargin={extraMargin}
                removeOverlay={removeOverlay}
                numberInRow={numberInRow}
                items={filteredVideos.map(video => {
                    let { youtubeLink, title, slug, type, imageUrl } = video
                    let youTubeId = getYouTubeId(youtubeLink)

                    return {
                        img: imageUrl || getPreviewUrl(youTubeId),
                        title: removeItemTitles ? null : multiline(title.title),
                        subtitle: type,
                        href: playlists ? youtubeLink : getVideoUrl(slug),
                    }
                })}
            />
        </>
    )
}
